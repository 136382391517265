import { useEffect } from 'react';
import { navigate } from 'gatsby';

import { NavigationBar } from '@src/components/NavigationBar';
import { NavigationBarType } from '@src/constants/constant';
import ProjectDetailPage from '@src/components/pages/ProjectDetail';
import { Layout } from '@src/components/Layout';
import { initLanguage } from '@src/utils/translation';
import { GeneralHead } from '@src/components/GeneralHead';
import { useQueryString } from '@src/hooks/useQueryString';

const btf2311 = [
  '8pVAQnbWmGW_PMrg2zqJpw',
  'H2wnPyp1RR92f9-r9lnnKg',
  'Teb0jihCbUj3KMpav4XH2A',
  'q2DNhx_SiA9J-j-g5jSLSg',
  '8Fm62KGsp_HORWOIG1r3zw',
  'JfrJUPNocQKIcTgJcrkNZg',
  'dpKgjI_pvoLJdpBm80DIeg',
  '5y_i-g1t4Gb9bxZAjcIk-g',
  'dOLGzoIy7EhIsYTSPSLgCw',
];

interface ProjectDetailProps {
  params?: {
    id?: string;
  };
}

export default function ProjectDetail(props: ProjectDetailProps) {
  const { isPreparing } = useQueryString();
  const projectId = props?.params?.id;

  useEffect(() => {
    initLanguage();
    (() => {
      const pathnameList = window.location.pathname.split('/');
      const projectId = pathnameList[pathnameList.length - 1];
      if (projectId === 'QE9VjBuYXXb3rYjEVoABvg') {
        navigate('/mpva/23-04-19', { replace: true });
      }
      if (btf2311.includes(projectId)) {
        navigate(`/btf/23-11/${projectId}`, { replace: true });
      }
      if (!projectId) {
        navigate('/projects');
      }
    })();
  }, [projectId]);

  return !isPreparing && projectId ? (
    <Layout
      header={({ scrollElement }) => (
        <NavigationBar
          scrollRef={scrollElement}
          type={NavigationBarType.GAME_DETAIL}
        />
      )}
      footer={<></>}
    >
      {({ scrollElement }) => (
        <ProjectDetailPage
          projectId={projectId}
          scrollElement={scrollElement}
        />
      )}
    </Layout>
  ) : undefined;
}

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>프로젝트 상세 - 리얼월드</title>
    </>
  );
};
